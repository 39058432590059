import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {Requerimento} from 'app/main/pages/requerimentos/requerimento.model';
import {RequerimentoService} from 'app/main/pages/requerimentos/requerimento.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {ResponsavelTecnico} from '../../../../../shared/models/responsavel-tecnico.model';
import {MatDialog} from '@angular/material';
import {ResponsaveisCorporativoService} from '../../../../../shared/services/responsaveis-corporativo.service';
import {DocumentoRL} from './documento-rl.model';
import {EnumTipoDocumentoRL} from './enum-tipo-documento-rl.model';
import {Pessoa} from '../../../../../shared/models/pessoa.model';
import {RepresentanteLegal} from '../../../../../shared/models/representante-legal.model';
import {FileValidationUtil} from '../../../../../shared/util/file-validation-util';
import {SnackBarService} from '../../../../../shared/snack-bar/snack-bar.service';
import {Subject} from 'rxjs';
import { EnumTipoDocumentoRT } from 'app/main/shared/models/enum-tipo-documento-rt.model';

@Component({
    selector: 'app-requerimento-representante-legal',
    templateUrl: './requerimento-representante-legal.component.html',
    styleUrls: ['./requerimento-representante-legal.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class RequerimentoRepresentanteLegalComponent implements OnInit {
    @Input() requerimento: Requerimento;
    @Input() isSomenteVisualizacao: boolean;
    @Input() altDocResp: boolean;
    @Output() listarResponsaveisLegaisEmitter = new Subject();
    showForm: FormControl = new FormControl(false);

    columnsConsulta: string[] = ['select', 'nome', 'cpfcnpj', 'email', 'formacao'];
    resultadoPesquisa: Pessoa[] | null = null;
    inputConsulta = new FormControl('', [Validators.required, Validators.minLength(1)]);
    anexos: DocumentoRL[] = EnumTipoDocumentoRL.listarDocumentosRestantes();
    fluxoAnexo: TipoProcesso | null = null;
    representanteLegalAnexado: ResponsavelTecnico[] = [];
    representanteLegalSemDocumentos: boolean;

    constructor(
        protected injector: Injector,
        protected requerimentoService: RequerimentoService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        public responsaveisCorporativoService: ResponsaveisCorporativoService
    ) {
    }

    ngOnInit(): void {
        this.listarRepresentanteLegal();
        if (this.requerimento.pessoa.tipo === 'PJ') {
            this.showForm.reset(true);
        }
    }

    /**
     * Consulta listagem de pessoas no SCORP e configura a tabela resultado com os dados.
     */
    consultarPessoas(): void {
        this.inputConsulta.markAllAsTouched();
        if (this.inputConsulta.valid) {
            const inputConsultaValue = this.inputConsulta.value.trim();
            // Valida se o valor consultado é diferente do ultimo consultado
            this.responsaveisCorporativoService.getRLbyCpfCnpj(inputConsultaValue).subscribe((result) => {
                if (this.requerimento.pessoa != null && this.requerimento.pessoa.cpfCnpj === result.cpfCnpj) {
                    this.snackBarService.showSuccess('O representante legal não pode ser o interessado');
                } else {
                    this.resultadoPesquisa = [result];
                    this.anexos = EnumTipoDocumentoRL.listarDocumentosRestantes();
                    this.fluxoAnexo = TipoProcesso.CRIAR;
                }
            }, error => {
                this.snackBarService.showError('Erro ao consultar pessoas', error);
            });
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    private listarRepresentanteLegal(): void {
        this.requerimentoService
            .listarRepresentantesLegais(this.requerimento.id).subscribe((content) => {
            this.representanteLegalAnexado = content;
            if (this.representanteLegalAnexado.length > 0) {
                this.showForm.disable();
                this.showForm.patchValue(true);
            } else {
                this.showForm.enable();
            }
            if (this.isSomenteVisualizacao) {
                this.showForm.disable();
            } else {
                this.showForm.enable(); 
            }
            
            if (this.altDocResp) {
                this.showForm.enable();
                this.isSomenteVisualizacao = false;
            }
        });
    }

    postRepresentanteLegal(): void {
        if (this.formRlIsValid()) {

            const formData = new FormData();
            formData.append('cpfCnpj', this.resultadoPesquisa[0].cpfCnpj);
            this.anexos.forEach(anexo => {
                if (anexo.tipo === EnumTipoDocumentoRL.PROCURACAO) {
                    if (anexo.arquivo) {
                        formData.append('fileProcuracao', anexo.arquivo);
                        formData.append('sigiloProcuracao', anexo.sigilo ? 'true' : 'false');
                    }
                } else if (anexo.tipo === EnumTipoDocumentoRL.OAB) {
                    if (anexo.arquivo) {
                        formData.append('fileOAB', anexo.arquivo);
                        formData.append('sigiloOAB', anexo.sigilo ? 'true' : 'false');
                    }
                }
            });

            this.requerimentoService.postRepresentanteLegal(this.requerimento.id, formData).subscribe((result) => {
                this.limparFluxoAnexos();

                this.snackBarService.showSuccess('Representante legal adicionado ao requerimento');
            }, (e) => {
                this.snackBarService.showError('Ocorreu um erro ao adicionar o Representante legal ao requerimento.', e);
            });
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    putRepresentanteLegal(): void {

        if (this.formRlIsValid()) {
            const formData = new FormData();

            this.anexos.forEach(anexo => {
                if (anexo.tipo === EnumTipoDocumentoRL.PROCURACAO) {
                    if (anexo.arquivo) {
                        formData.append('fileProcuracao', anexo.arquivo);
                    }
                    if (anexo.nomeArquivo) {
                        formData.append('sigiloProcuracao', anexo.sigilo ? 'true' : 'false');
                    }
                } else if (anexo.tipo === EnumTipoDocumentoRL.OAB) {
                    if (anexo.arquivo) {
                        formData.append('fileOAB', anexo.arquivo);
                    }
                    if (anexo.nomeArquivo) {
                        formData.append('sigiloOAB', anexo.sigilo ? 'true' : 'false');
                    }
                }
            });

            if (this.isRepresentanteLegalSemDocumentos()) {
                this.requerimentoService.putRepresentanteLegalSemArquivos(this.requerimento.id, this.resultadoPesquisa[0].cpfCnpj, formData).subscribe((result) => {
                    this.limparFluxoAnexos();
                    this.snackBarService.showSuccess('Representante legal atualizado');
                }, (error) => {
                    this.snackBarService.showError('Ocorreu um erro ao atualizar o Representante legal.', error);
                });
            } else {
                this.requerimentoService.putRepresentanteLegal(this.requerimento.id, this.resultadoPesquisa[0].cpfCnpj, formData).subscribe((result) => {
                    this.limparFluxoAnexos();

                    this.snackBarService.showSuccess('Representante legal atualizado');
                }, (error) => {
                    this.snackBarService.showError('Ocorreu um erro ao atualizar o Representante legal.', error);
                });
            }
        } else {
            this.snackBarService.showAlert('Campo(s) obrigatório(s) não preenchido(s). Favor realize o preenchimento do(s) mesmo(s).');
        }
    }

    removerAnexo(index: number): void {
        const anexo = this.anexos[index];
        anexo.arquivo = null;
        anexo.nomeArquivo = null;
        document.getElementById(`input-arquivo-${index}`)['value'] = null;
    }

    selecionarAnexo(file, index: number): void {
        if (FileValidationUtil.invalidSize(file, FileValidationUtil.size('5MB'))) {
            this.snackBarService.showAlert('Arquivo com tamanho inválido, o tamanho limite é 5MB.');
            document.getElementById(`input-arquivo-${index}`)['value'] = null;
            return;
        }
        const anexo = this.anexos[index];
        anexo.arquivo = file;
        anexo.nomeArquivo = file.name;
    }

    formRlIsValid(): boolean {
        let isValid = true;
        this.anexos.forEach(anexo => {
            if (!(anexo.arquivo instanceof File) && anexo.obrigatorio && !anexo.id) {
                isValid = false;
            }
        });
        if (!(this.resultadoPesquisa)) {
            isValid = false;
        }
        return isValid;
    }

    /**
     * Limpa todas as variáveis utilizadas no fluxo de adicionar o RT
     */
    limparFluxoAnexos(): void {
        this.inputConsulta.setValue('');
        this.resultadoPesquisa = null;
        this.anexos = [];
        this.representanteLegalSemDocumentos = false;
        this.listarRepresentanteLegal();
        this.listarResponsaveisLegaisEmitter.next();
    }

    visualizarRepresentanteLegal(responsavelTecnico: RepresentanteLegal): void {
        this.requerimentoService.listarDocumentosDoRepresentanteLegal(
            this.requerimento.id,
            responsavelTecnico.pessoa.cpfCnpj).subscribe((result) => {
            this.resultadoPesquisa = [responsavelTecnico.pessoa];
            this.fluxoAnexo = TipoProcesso.EDITAR;
            if (result.length == 0 && !this.resultadoPesquisa) {
                this.fluxoAnexo = TipoProcesso.CRIAR;
                this.representanteLegalSemDocumentos = true;
            } 
            this.anexos = EnumTipoDocumentoRL.listarDocumentosRestantes(result);
        }, error => {
            this.snackBarService.showError('Erro ao carregar documentos do Representante legal.');
        });
    }

    removeRepresentanteLegal(responsavelTecnico: ResponsavelTecnico): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {label: responsavelTecnico.pessoa.nome}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.requerimentoService.deleteRepresentanteLegal(this.requerimento.id, responsavelTecnico.pessoa.cpfCnpj)
                    .subscribe(() => {
                            this.snackBarService.showSuccess('Representante legal excluído com sucesso!');
                            this.limparFluxoAnexos();
                        },
                        () => this.snackBarService.showError('Erro ao excluir Representante legal.')
                    );
            }
        });
    }

    public visualizarDocumento(documento: DocumentoRL): void {
        this.requerimentoService.downloadDocumentoRepresentanteLegal(this.requerimento.id, documento).subscribe((blob) => {
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
        });
    }

    public downloadDocumento(documento: DocumentoRL): void {
        window.open(
            `${this.requerimentoService.urlResource}/${this.requerimento.id}/representantes-legais/
            ${documento.representanteLegal.pessoa.cpfCnpj}/documentos/${EnumTipoDocumentoRL.toJson(documento.tipo)}`
        );
    }

    isCriacao(): boolean {
        return this.fluxoAnexo === TipoProcesso.CRIAR;
    }

    isEdicao(): boolean {
        return this.fluxoAnexo === TipoProcesso.EDITAR;
    }

    isResponsavelPJ(): boolean {
        return this.requerimento.pessoa && this.requerimento.pessoa.tipo === 'PJ';
    }

    isRepresentanteLegalSemDocumentos(): boolean {
        return this.resultadoPesquisa[0].id && this.representanteLegalSemDocumentos;
    }

    isDocumentoOabAdiconado(): boolean {
        const anexo = this.anexos.find(a => a.tipo === EnumTipoDocumentoRL.OAB);
        return !!anexo && !!anexo.id;
    }

    isDocumentoProcuracaoAdiconado(): boolean {
        const anexo = this.anexos.find(a => a.tipo === EnumTipoDocumentoRL.PROCURACAO);
        return !!anexo && !!anexo.id;
    }

    isDesabilitaAlterarRepresentanteLegal(): boolean {
        return this.isSomenteVisualizacao 
            || this.requerimento.tipoRequerimento.isFluxoCancelar 
            || this.requerimento.tipoRequerimento.isFluxoReabilitar
            || this.requerimento.tipoRequerimento.isFluxoRenovar; 
    }
}

enum TipoProcesso {
    EDITAR,
    CRIAR
}
